import Vue from 'vue'

export default Vue.extend({
  methods: {
    getFromInfo (emailMessage: Record<string, any> | null | undefined, key: string): string | null {
      if (emailMessage && emailMessage.mail && emailMessage.mail.from && emailMessage.mail.from.value.length > 0) {
        return key === 'address' ? emailMessage.mail.from.value[0].address : emailMessage.mail.from.value[0].name
      }

      return null
    }
  }
})
