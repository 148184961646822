import Vue, { VueConstructor } from 'vue'
import useValidator from '@/mixins/useValidator'

export default (Vue as VueConstructor<Vue & InstanceType<typeof useValidator>>).extend({
  mixins: [useValidator],
  computed: {
    adminList () {
      return this.$store.state.userRoles.collection
    },
    statusList () {
      return [
        {
          value: 'open',
          label: 'Ouverte'
        },
        {
          value: 'close',
          label: 'Fermée'
        }
      ]
    },
    sourceList () {
      return [
        {
          value: 'application',
          label: 'Application'
        },
        {
          value: 'mail',
          label: 'Mail'
        }
      ]
    },
    assignList () {
      const list = [
        {
          value: 'none',
          label: 'Personne'
        }
      ]

      for (const admin of this.adminList) {
        if (!admin.user) {
          continue
        }

        list.push({
          value: admin.user.id,
          label: `${admin.user.firstName} ${admin.user.lastName}`
        })
      }

      return list
    },
    validationStatusRules () {
      return {
        isClosed: 'required|boolean'
      }
    },
    validationAssignRules () {
      return {
        assigneeId: 'present'
      }
    }
  },
  methods: {
    async updateAssignee (value: string, item: Record<string, any> | null | undefined) {
      const form = {
        assigneeId: value
      }

      const id = item ? item.id : ''
      const reference = item ? item.reference : ''

      try {
        if (!this.validate(form, this.validationAssignRules)) {
          this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
          return
        }

        await this.$store.dispatch('supportRequests/assign', { ...form, id })

        const assign = this.assignList.find(x => x.value === value)
        const assignTo = assign ? assign.label : 'Personne'

        this.$toasted.success(`Demande référence: ${reference} est assignée à: ${assignTo}`)
      } catch (e) {
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      }
    },
    async updateStatus (value: string, item: Record<string, any> | null | undefined) {
      const form = {
        isClosed: value === 'close'
      }

      const id = item ? item.id : null
      const reference = item ? item.reference : null

      try {
        if (!this.validate(form, this.validationStatusRules)) {
          this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
          return
        }

        await this.$store.dispatch('supportRequests/updateStatus', { ...form, id })

        this.$toasted.success(`Demande référence: ${reference} est maintenant ${form.isClosed ? 'fermée' : 'ouverte'}`)
      } catch (e) {
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      }
    },
    async fetchAdminList () {
      await this.$store.dispatch('userRoles/list', { roleName: 'admin' })
    },
    displayValueStatus (item: Record<string, any> | undefined) {
      if (!item) {
        return ''
      }

      return item.isClosed ? 'Fermée' : 'Ouverte'
    },
    displayValueAssign (item: Record<string, any> | undefined) {
      if (!item) {
        return ''
      }

      return item.assignee ? `${item.assignee.firstName} ${item.assignee.lastName}` : 'Personne'
    },
    defaultValueStatus (item: Record<string, any> | undefined) {
      if (!item) {
        return 'open'
      }

      return item.isClosed ? 'close' : 'open'
    },
    defaultValueAssign (item: Record<string, any> | undefined) {
      if (!item) {
        return null
      }

      return item.assignee ? item.assignee.id : null
    }
  },
  created () {
    this.fetchAdminList()
  }
})
