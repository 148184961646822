<template>
  <img v-if="src" class="image" :src="src" :class="`image-${this.size}`" :alt="alt" />
  <div v-else class="no-image-available" :class="`image-${this.size}`">
    <p class="d-block mb-0 small text-center">
      Aucune image disponible
    </p>
  </div>
</template>

<script>

export default {
  props: {
    src: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator: value => ['sm', 'md'].includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-sm{
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
}

.image-md {
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
}

.image {
  position: relative;
  background: #ffffff;
  object-fit: contain;
  object-position: center;
}

.no-image-available {
  color: #757575;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
