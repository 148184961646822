<template>
  <div class="message-composer">
    <template v-if="!isSubmitted">
      <textarea
        class="form-control message-input"
        placeholder="Rédiger un message..."
        v-model="value"
        :style="{
          'height': `${editorHeight}px`
        }"
        @focus="setFocus"
        @blur="unsetFocus"
      ></textarea>
      <div class="action-bar mt-3" v-if="isActiveState">
        <button class="btn btn-primary btn-sm" :disabled="!canSubmit" @click="handleSubmit">Envoyer</button>
      </div>
    </template>
    <template v-else>
      <p>
        <strong>Merci!</strong>
        <br>Votre message a bien été envoyé.
      </p>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isFocused: false,
      isProcessing: false,
      isSubmitted: false,
      value: ''
    }
  },
  computed: {
    isActiveState () {
      return this.isFocused || this.value.length
    },
    editorHeight () {
      const minHeight = this.isActiveState ? 72 : 36
      const numberOfLineBreaks = this.value ? (this.value.match(/\n/g) || []).length : 0
      const contentHeight = numberOfLineBreaks > 0 ? 24 * numberOfLineBreaks + 36 : 36
      return contentHeight > minHeight ? contentHeight : minHeight
    },
    canSubmit () {
      return this.value.length && !this.isProcessing
    }
  },
  methods: {
    setFocus () {
      this.isFocused = true
    },
    unsetFocus () {
      this.isFocused = false
    },
    onSubmitSuccess (displayConfirmation = true) {
      this.isProcessing = false
      this.value = ''

      if (displayConfirmation) {
        this.isSubmitted = true
      }
    },
    onError () {
      this.isProcessing = false
    },
    handleSubmit () {
      this.isProcessing = true
      this.$emit('submit', this.value, this.onSubmitSuccess, this.onError)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-input {
  resize: none;
}
</style>
