import config from '@/config'
import { File as StorageFile } from '@/types/files'

/**
 * Returns the public url for S3 Storage file
 * @param file
 */
export const getStorageFileSource = (file: StorageFile) => {
  return `${config.storage.cdnUrl}/${file.path}`
}
