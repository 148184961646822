<template>
  <app-layout>
    <div class="px-5 container container-center">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Détail de la demande</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'supportRequests.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-9 col-xl-9">
            <div class="card mb-4">
              <div class="card-body">
                <dl class="row">
                  <dt class="col-4">Contact</dt>
                  <dd class="col-8">{{ contact }}</dd>
                </dl>
                <dl class="row" v-if="supportRequest && supportRequest.storeId">
                  <dt class="col-4">Boutique</dt>
                  <dd class="col-8">{{ supportRequest && supportRequest.store ? supportRequest.store.name : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Demande ouverte le</dt>
                  <dd class="col-8">{{ supportRequest ? $longDateTime(supportRequest.createdAt) : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Dernier message</dt>
                  <dd class="col-8">{{ lastMessage ? $longDateTime(lastMessage.createdAt) : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Source</dt>
                  <dd class="col-8">{{ source }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">
                    <inline-select
                      :item="supportRequest"
                      :callBack="callBackStatus"
                      :displayValue="displayValueStatus(supportRequest)"
                      :list="statusList"
                      :defaultValue="defaultValueStatus(supportRequest)"
                    />
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Assigné à</dt>
                  <dd class="col-8">
                    <inline-select
                      :item="supportRequest"
                      :callBack="callBackAssign"
                      :displayValue="displayValueAssign(supportRequest)"
                      :list="assignList"
                      :defaultValue="defaultValueAssign(supportRequest)"
                    />
                  </dd>
                </dl>
              </div>
            </div>

            <h3 class="subtitle my-4">Messages</h3>

            <div class="mb-4">
              <message-composer @submit="sendMessage" />
            </div>

            <div class="card mb-4" v-for="message in orderedMessages" :key="message.id">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="author">
                    <strong>{{ getMessageAuthor(message) }}</strong>
                  </div>
                  <div class="date">
                    <small>{{ getMessageDate(message) }}</small>
                  </div>
                </div>

                <div v-if="message.meta && message.meta.attachments" class="d-flex align-items-center mb-3">
                  <div>
                    Attachments:
                    <span
                      v-for="(attachment, key) in message.meta.attachments"
                      :key="key"
                    >
                      <span v-if="key > 0">|</span>
                      <span
                        @click.prevent="download(message.id, attachment.id)"
                        class="btn btn-link btn-sm"
                      >
                        {{ attachment.originalFileName ? attachment.originalFileName : attachment.fileName }}
                      </span>
                    </span>
                  </div>
                </div>

                <div class="message">{{ message.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { orderBy, get } from 'lodash-es'
import MessageComposer from '@/components/common/MessageComposer'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import download from '@/mixins/download'
import formats from '@/mixins/formats'
import supportRequestFunctions from '@/mixins/supportRequestFunctions'
import InlineSelect from '@/components/common/InlineSelect'
import emailMessage from '@/mixins/emailMessage'
import http from '@/utils/http'

export default {
  mixins: [userData, formatsDate, supportRequestFunctions, formats, emailMessage, download],
  components: {
    MessageComposer,
    InlineSelect
  },
  computed: {
    source () {
      if (this.supportRequest) {
        return this.capitalize(this.supportRequest.source)
      }

      return ''
    },
    contact () {
      if (this.supportRequest) {
        if (this.supportRequest.source === 'application' || this.supportRequest.user) {
          return this.supportRequest && this.supportRequest.user ? `${this.supportRequest.user.firstName} ${this.supportRequest.user.lastName}` : '-'
        }

        const orderMessage = orderBy(this.supportRequest.messages, ['createdAt'], ['asc'])
        const fromAddress = this.getFromInfo(orderMessage[0].emailMessage, 'address')
        const fromName = this.getFromInfo(orderMessage[0].emailMessage, 'name')

        return `${fromName ? `${fromName} ` : ''}${fromAddress ? `(${fromAddress})` : ''}`
      }

      return '-'
    },
    supportRequest () {
      return this.$store.getters['supportRequests/getDetailsById'](this.$route.params.id)
    },
    orderedMessages () {
      return this.supportRequest ? orderBy(this.supportRequest.messages, ['createdAt'], ['desc']) : []
    },
    lastMessage () {
      return this.orderedMessages.length ? this.orderedMessages[0] : null
    }
  },
  methods: {
    async download (messageId, attachmentId) {
      await this.downloadFile(`/v1/support/requests/${this.$route.params.id}/message/${messageId}/attachment/${attachmentId}`)
    },
    async callBackStatus (value, item) {
      await this.updateStatus(value, item)
      await this.fetchData()
    },
    async callBackAssign (value, item) {
      await this.updateAssignee(value, item)
      await this.fetchData()
    },
    fetchData () {
      this.$store.dispatch('supportRequests/fetchById', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })
    },
    getMessageAuthor (message) {
      if (message.authorType === 'support') {
        return 'Équipe support'
      }

      if (this.supportRequest.source === 'application' || message.authorId) {
        return `${get(message, 'author.firstName')} ${get(message, 'author.lastName')}`
      }

      const fromAddress = this.getFromInfo(message.emailMessage, 'address')
      const fromName = this.getFromInfo(message.emailMessage, 'name')

      return `${fromName ? `${fromName} ` : ''}${fromAddress ? `(${fromAddress})` : ''}`
    },
    getMessageDate (message) {
      return this.$shortDateTime(message.createdAt)
    },
    async sendMessage (message, onSuccess, onError) {
      try {
        await http.post(`/v1/support/requests/${this.$route.params.id}/messages`, {
          message
        })

        this.fetchData()
        onSuccess(false)

        this.$toasted.success('Votre message a bien été envoyé.')
      } catch {
        onError()
        this.$toasted.error('Erreur lors de l\'envoi. Veuillez réessayer.')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.2rem;
}

.message {
  white-space: pre-wrap;
}
</style>
