<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Demandes de support</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par référence" @change="changePage(1)" v-model="textFilter">
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" @change="resetActiveFilter" v-model="statusFilter">
                        <option value="">Statut</option>
                        <option value="open">Ouvert</option>
                        <option value="close">Fermée</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" v-model="sourceFilter" @change="resetActiveFilter">
                        <option value="">Source</option>
                        <option v-for="source in sourceList" :key="source.value" :value="source.value">{{ source.label }}</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" v-model="assignFilter" @change="resetActiveFilter">
                        <option value="">Assigné à</option>
                        <option v-for="assign in assignList" :key="assign.value" :value="assign.value">{{ assign.label }}</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" v-model="waitingReplyFilter" @change="resetActiveFilter">
                        <option value="">En attente d'une réponse</option>
                        <option value="yes">Oui</option>
                        <option value="no">Non</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="custom-select mobile-wid-100" v-model="activeFilter" @click.prevent="activeFiltering(activeFilter)">
                        <option value="">Filtre rapide</option>
                        <option value="myRequests">Toutes mes demandes</option>
                        <option value="myActiveRequests">Mes demandes actives</option>
                        <option value="myOpenRequests">Mes demandes ouvertes</option>
                        <option value="myCloseRequests">Mes demandes fermées</option>
                        <option value="newRequests">Nouvelles demandes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline">
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1">
                      <a class="btn btn-sm btn-link" @click.prevent="syncData" >
                        <fa-icon class="fa-2x text-info" :icon="['fas', 'sync']"></fa-icon>
                      </a>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="activeFiltering('reset')" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4 support-requests">
          <div class="card-body p-0">
            <div v-if="requests.length === 0 && !isFetching" class="card-body text-center">
              <div class="py-3">Vous n'avez aucune demande de support.</div>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="requests"
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              no-top-border
              hover
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.pageCount > 1 && !isFetching" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination :current-page="page" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { orderBy } from 'lodash-es'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'
import supportRequestFunctions from '@/mixins/supportRequestFunctions'
import formats from '@/mixins/formats'
import emailMessage from '@/mixins/emailMessage'
import InlineSelect from '@/components/common/InlineSelect'
import LinkTo from '@/components/common/LinkTo'
import Pagination from '@/components/common/Pagination'

export default {
  components: {
    Pagination
  },
  mixins: [formatsDate, userData, formatsCurrency, supportRequestFunctions, formats, emailMessage],
  computed: {
    isLoading () {
      return this.$store.state.supportRequests.isLoading
    },
    isFetching () {
      return this.$store.state.supportRequests.isFetching
    },
    cols () {
      return [
        {
          header: 'Référence',
          property: 'reference',
          isSortable: true
        },
        {
          header: 'Source',
          property: 'source',
          method: (item) => {
            return this.capitalize(item.source)
          },
          isSortable: true
        },
        {
          header: 'Demandeur',
          property: 'user.firstName',
          method: (item) => {
            if (item.source === 'application') {
              return item.storeId ? item.store.name : `${item.user.firstName} ${item.user.lastName}`
            }

            if (item.user) {
              return `${item.user.firstName} ${item.user.lastName}`
            }

            const orderMessage = orderBy(item.messages, ['createdAt'], ['asc'])
            const emailMessage = orderMessage[0] && orderMessage[0].emailMessage ? orderMessage[0].emailMessage : {}
            const fromAddress = this.getFromInfo(emailMessage, 'address')
            const fromName = this.getFromInfo(emailMessage, 'name')

            return `${fromName ? `${fromName} ` : ''}${fromAddress ? `(${fromAddress})` : ''}`
          },
          isSortable: false
        },
        {
          header: 'Ouverte le',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.createdAt)
          },
          isSortable: true
        },
        {
          header: 'Statut',
          property: 'isClosed',
          component: InlineSelect,
          props: (item) => {
            return {
              item,
              callBack: async (value, item) => {
                await this.updateStatus(value, item)
                await this.fetchRequests()
              },
              displayValue: this.displayValueStatus(item),
              list: this.statusList,
              defaultValue: this.defaultValueStatus(item)
            }
          },
          isSortable: true
        },
        {
          header: "En attente d'une réponse",
          property: 'isWaitingReply',
          method: (item) => {
            return item.isWaitingReply ? 'Oui' : 'Non'
          },
          isSortable: true
        },
        {
          header: 'Assigné à',
          property: 'assignee.firstName',
          component: InlineSelect,
          props: (item) => {
            return {
              item,
              callBack: async (value, item) => {
                await this.updateAssignee(value, item)
                await this.fetchRequests()
              },
              displayValue: this.displayValueAssign(item),
              list: this.assignList,
              defaultValue: this.defaultValueAssign(item)
            }
          },
          isSortable: true
        },
        {
          header: 'Action',
          property: 'id',
          component: LinkTo,
          props: (item) => {
            return {
              haveLink: true,
              to: { name: 'supportRequests.details', params: { id: item.id } },
              linkText: 'Voir les messages'
            }
          },
          isSortable: false
        }
      ]
    },
    paginatedData () {
      return this.$store.state.supportRequests.paginatedData
    },
    requests () {
      return this.paginatedData.data
    },
    textFilter: {
      get () {
        return this.$store.state.supportRequests.textFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_TEXT_FILTER', value)
      }
    },
    statusFilter: {
      get () {
        return this.$store.state.supportRequests.statusFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_STATUS_FILTER', value)
      }
    },
    assignFilter: {
      get () {
        return this.$store.state.supportRequests.assignFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_ASSIGN_FILTER', value)
      }
    },
    waitingReplyFilter: {
      get () {
        return this.$store.state.supportRequests.waitingReplyFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_WAITING_REPLY_FILTER', value)
      }
    },
    activeFilter: {
      get () {
        return this.$store.state.supportRequests.activeFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_ACTIVE_FILTER', value)
      }
    },
    sourceFilter: {
      get () {
        return this.$store.state.supportRequests.sourceFilter
      },
      set (value) {
        this.$store.commit('supportRequests/SET_SOURCE_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.supportRequests.perPage
      },
      set (value) {
        this.$store.commit('supportRequests/SET_PER_PAGE', value)
      }
    },
    sortDirection: {
      get () {
        return this.$store.state.supportRequests.sortDirection
      },
      set (value) {
        this.$store.commit('supportRequests/SET_SORT_DIRECTION', value)
      }
    },
    sortBy: {
      get () {
        return this.$store.state.supportRequests.sortBy
      },
      set (value) {
        this.$store.commit('supportRequests/SET_SORT', value)
      }
    },
    page () {
      return this.$store.state.supportRequests.page
    }
  },
  watch: {
    activeFilter: {
      handler (filter) {
        this.activeFiltering(filter)
      }
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchRequests(page)
    },
    async fetchRequests (page) {
      await this.$store.dispatch('supportRequests/fetch', { page, userId: this.userId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchRequests()
    },
    resetActiveFilter () {
      if (this.activeFilter !== '') {
        this.activeFilter = ''
      }

      this.changePage(1)
    },
    activeFilterClass (filter) {
      return filter === this.activeFilter ? 'btn-primary' : 'btn-outline-primary'
    },
    async syncData () {
      await this.fetchRequests(this.page)
      this.$toasted.info('Les demandes sont actualisées')
    },
    activeFiltering (filter) {
      this.textFilter = ''
      this.sourceFilter = ''

      switch (filter) {
        case 'myRequests':
          this.activeFilter = 'myRequests'
          this.statusFilter = ''
          this.assignFilter = this.currentUser ? this.currentUser.id : ''
          this.waitingReplyFilter = ''
          break

        case 'myActiveRequests':
          this.activeFilter = 'myActiveRequests'
          this.statusFilter = 'open'
          this.assignFilter = this.currentUser ? this.currentUser.id : ''
          this.waitingReplyFilter = 'yes'
          break

        case 'myOpenRequests':
          this.activeFilter = 'myOpenRequests'
          this.statusFilter = 'open'
          this.assignFilter = this.currentUser ? this.currentUser.id : ''
          this.waitingReplyFilter = ''
          break

        case 'myCloseRequests':
          this.activeFilter = 'myCloseRequests'
          this.statusFilter = 'close'
          this.assignFilter = this.currentUser ? this.currentUser.id : ''
          this.waitingReplyFilter = ''
          break

        case 'newRequests':
          this.activeFilter = 'newRequests'
          this.statusFilter = 'open'
          this.assignFilter = 'none'
          this.waitingReplyFilter = ''
          break

        case 'reset':
          this.activeFilter = ''
          this.statusFilter = ''
          this.assignFilter = ''
          this.waitingReplyFilter = ''
          this.sortBy = ''
          this.sortDirection = 'DESC'
          this.$toasted.info('Les filtres sont réinitialisés')
          break
      }

      this.fetchRequests()
    }
  },
  created () {
    this.fetchRequests()
  }
}
</script>

<style lang="scss">
.support-requests {
  .col-date {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }
}
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
